import { For, Match, Show, Switch, createEffect, createMemo, createSignal, untrack } from "solid-js";
import type { MainProps, ParticipantsCardProps, TeamsCardProps } from "./_model";
import { teamsType, TeamsStatus } from "./const";
import { ParticipantsCard, TeamsCard } from "./components";
import { DropDown, SearchTool } from ":src/components/form";
import { solidstate } from ":shared/utils/state-manager";
import { get_participants, get_teams } from "./apis";
import { Platform } from ":shared/components/loaders";
import { Loader2 } from ":src/components/loaders";

export default function Participants(props: MainProps) {
  const [pageType, setPageType] = createSignal<string>("participants");
  const $ParticipantsList = solidstate.createAsync(get_participants);
  const $TeamsList = solidstate.createAsync(get_teams);
  const participants_keywords = ["first_name", "last_name", "major", "bio", "track"];
  const teams_keywords = ["track", "status", "team_name", "project_description"];

  const $applied_filter = solidstate.create(
    {} as { search?: string; track?: string; team_type?: string; team_status?: string }
  );

  const $tracks = createMemo(() => {
    const tracks = [];
    $ParticipantsList?.value?.forEach((s, index) => {
      if (!tracks.find((f) => f.value === s.track) && s.track) {
        tracks.push({
          id: index,
          value: s.track,
        });
      }
    });
    return tracks;
  });

  const setParticipantsPage = () => {
    setPageType("participants");
  };
  const setTeamsPage = () => {
    setPageType("teams");
  };

  return (
    <section class="flex flex-col w-full min-h-full h-full px-5 py-3 space-y-1vh <md:(px-2 py-1 space-y-0.7vh)">
      <h1 class="text-xl font-[600] text-black z-1 <md:(text-22px)">Explore Participants and Teams</h1>
      <Switch>
        <Match when={$ParticipantsList.state.loading}>
          <div class="flex w-full min-h-screen md:h-full items-center justify-center">
            <Loader2 msg="Loading Details..." />
          </div>
        </Match>
        <Match when={$ParticipantsList.state.failed}>an error occurred while fetching {$ParticipantsList.error}</Match>
        <Match when={$TeamsList.state.failed}>an error occurred while fetching {$TeamsList.error}</Match>
        <Match when={$ParticipantsList.state.success}>
          <div
            class={`
              flex justify-start items-center gap-4
              children:(text-24px text#gray font-[600] border-b-4 w-fit duration-200 cursor-pointer <md:(text-2vh))
              `}
          >
            <p
              onClick={setParticipantsPage}
              class={pageType() === "participants" && "text#orange border#orange border-solid"}
            >
              Participants
            </p>
            <p onClick={setTeamsPage} class={pageType() === "teams" && "text#orange border#orange border-solid"}>
              Teams
            </p>
          </div>
          <div class="w-full flex flex-row justify-between items-center gap-3vh !<md:(flex-col gap-1.4vh )">
            <div class="w-full">
              <SearchTool onSearch={(value) => $applied_filter.set((s) => ({ ...s, search: value }))} />
            </div>
            <div class="w-[60%] flex flex-row gap-3vh justify-center items-center !<md:(w-full gap-1.5vh)">
              <p class="text-black text-[18px] min-w-fit">Filter by</p>
              <DropDown
                label="All Tracks"
                options={$tracks()}
                onValueChoose={(value) => $applied_filter.set((s) => ({ ...s, track: value }))}
              />
              <Show
                when={pageType() === "participants"}
                fallback={
                  <DropDown
                    label="Status"
                    options={TeamsStatus}
                    onValueChoose={(value) => $applied_filter.set((s) => ({ ...s, team_status: value }))}
                  />
                }
              >
                <DropDown
                  label="Teams"
                  options={teamsType}
                  onValueChoose={(value) => $applied_filter.set((s) => ({ ...s, team_type: value }))}
                />
              </Show>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-4 h-fit py-1vh  overflow-x-hidden !overflow-y-auto <md:(grid-cols-1 py-2vh)">
            <Switch>
              <Match when={pageType() === "participants" && $ParticipantsList.state.success}>
                <For each={$ParticipantsList.value}>
                  {(participant: ParticipantsCardProps) => {
                    const show = createMemo(() => {
                      if (
                        $applied_filter.value.search &&
                        !participants_keywords.some((prop) =>
                          participant[prop]?.toLowerCase().includes($applied_filter.value.search.toLowerCase())
                        )
                      ) {
                        return false;
                      }
                      if ($applied_filter.value.track && participant.track !== $applied_filter.value.track) {
                        return false;
                      }
                      if (
                        $applied_filter.value.team_type &&
                        (($applied_filter.value.team_type === "with team" && !participant.team) ||
                          ($applied_filter.value.team_type === "without team" && participant.team))
                      ) {
                        return false;
                      }
                      return true;
                    });

                    return (
                      <Show when={show()}>
                        <ParticipantsCard {...participant} />
                      </Show>
                    );
                  }}
                </For>
              </Match>

              <Match when={pageType() === "teams"}>
                <Show
                  fallback={
                    <div class="flex w-full !col-span-3 !row-span-3 h-full justify-center items-center">
                      <Loader2 msg="Loading Teams Details..." />
                    </div>
                  }
                  when={$TeamsList.state.success}
                >
                  <For each={$TeamsList.value}>
                    {(team: TeamsCardProps) => {
                      const show = createMemo(() => {
                        if (
                          $applied_filter.value.search &&
                          !teams_keywords.some((prop) =>
                            team[prop]?.toLowerCase().includes($applied_filter.value.search.toLowerCase())
                          )
                        ) {
                          return false;
                        }
                        if ($applied_filter.value.track && team.track !== $applied_filter.value.track) {
                          return false;
                        }
                        if (
                          $applied_filter.value.team_status &&
                          team.status.toLowerCase() !== $applied_filter.value.team_status.toLowerCase()
                        ) {
                          return false;
                        }
                        return true;
                      });
                      return (
                        <Show when={show()}>
                          <TeamsCard {...team} />
                        </Show>
                      );
                    }}
                  </For>
                </Show>
              </Match>
            </Switch>
          </div>
        </Match>
      </Switch>
    </section>
  );
}
